import '../styles/index.scss';

if (process.env.NODE_ENV === 'development') {
  require('../home.html');
}

console.log('webpack starterkit');

// import Swiper JS
import Swiper, { Autoplay, Lazy, EffectFade } from 'swiper';
// import Swiper styles
import 'swiper/swiper-bundle.min.css';


var slidesLength = document.querySelectorAll('.swiper-slide').length;

Swiper.use([Autoplay, Lazy, EffectFade]);
var mySwiper = new Swiper('.swiper-container', {
  // slidesPerView: 'auto',
  // spaceBetween: 30,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  centeredSlides: true,
  loop: true,
  grabCursor: true,
  speed: 500,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  lazy: {
    loadPrevNext: true,
    // loadPrevNextAmount: 3,
  },
  watchSlidesVisibility: true,
});

mySwiper.on('slideChange', function () {
  document.querySelector('.slide-counter').innerHTML = (mySwiper.realIndex + 1) + ' / ' + slidesLength;
  document.querySelector('.slide-title').innerHTML = document.querySelector('.swiper-slide-visible img').dataset['title'];
});

document.querySelector('.arrow.right').addEventListener('click', function(){
  mySwiper.slideNext();
});

document.querySelector('.arrow.left').addEventListener('click', function(){
  mySwiper.slidePrev();
});

const openMenuEl = document.querySelector('.open-menu');
const closeMenuEl = document.querySelector('.close-menu');
const mobileMenuEl = document.querySelector('.mobile-menu');

openMenuEl.onclick = function() {
  mobileMenuEl.classList.add('open');
};

closeMenuEl.onclick = function() {
  mobileMenuEl.classList.remove('open');
};
